@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes animloader {
  0% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px, -30px 0 0 -2px;
  }
  25% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 -2px, -30px 0 0 2px;
  }
  50% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 -2px, -10px 0 0 2px, -30px 0 0 -2px;
  }
  75% {
    box-shadow: 10px 0 0 2px, 30px 0 0 -2px, -10px 0 0 -2px, -30px 0 0 -2px;
  }
  100% {
    box-shadow: 10px 0 0 -2px, 30px 0 0 2px, -10px 0 0 -2px, -30px 0 0 -2px;
  }
}

body {
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  line-height: 130%;
  font-size: 24px;
}

/* .ymaps-2-1-79-copyright__logo {
  display: none !important;
}

.ymaps-2-1-79-copyrights-pane {
  display: none !important;
} */
